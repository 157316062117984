import React from "react";
import CancerStatistic from "../CancerStatistic";
import CancerNews from "./announcement/CancerNews";
import styled from "styled-components";
import BMI from "../BMI";
import PopUpZone from "./announcement/PopUpZone";

import Announcement from "./announcement/Announcement";

const Contents = () => {
  return (
    <Container>
      <Wrapper>
        <NewsContainer>
          <Announcement />
          <PopUpZone />
        </NewsContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 120px 0 40px 0;
  /* background-color: red; */
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 1279px) {
    padding: 60px 0 20px 0;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;

  @media screen and (max-width: 1279px) {
    padding: 0 20px;
  }
`;

const NewsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    gap: 40px;
    justify-content: center;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export default Contents;

import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import * as s from "../../styles/chatbot/style.chatbot";

import chatbotIcon from "../../assets/common/chatbot_icon.png";
import ChatbotSelect from "./ChatbotSelect";
import setting from "assets/vector/chatbot/setting.svg";
import close from "assets/vector/chatbot/close.svg";
import loading1 from "assets/vector/chatbot/loading1.svg";
import loading2 from "assets/vector/chatbot/loading2.svg";
import loading3 from "assets/vector/chatbot/loading3.svg";
import send from "assets/vector/chatbot/send.svg";
import * as T from "../../types/types.chatbot";
import { requestChatbot } from "../../api/ChatBotApi";
import { useChatbot } from "./ChatbotContext";
import useHospitalStore from "../../zustand/store/hospital";
import { useNavigate } from "react-router-dom";
import { ToastError } from "libs/toastifyAlert";
import { TOAST_TEXT } from "enums/messages";
import ChatbotRecommendation from "./ChatbotRecommendation";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/auth/authSlice";
import Allowd from "assets/chatbot/Allowed.png";
import NotAllowed from "assets/chatbot/NotAllowed.png";
import chatInfo from "assets/chatbot/chatInfo.png";
import Info from "assets/chatbot/info.png";
import "react-tooltip/dist/react-tooltip.css"; //반드시 필요
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Mic } from "lucide-react";
import AudioRecorderPython from "feature/AudioRecorderPython";

interface ChatbotModalProps {
  onClose: () => void;
}
interface Message {
  sender: "ai" | "user";
  text: string;
}
const ChatbotModal = ({ onClose }: ChatbotModalProps) => {
  const { cancerSelect, setCancerSelect } = useChatbot();
  const [language, setLanguage] = useState("한국어");
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showHospital, setShowHospital] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [currentMsg, setCurrentMsg] = useState(-1);

  const inputRef = useRef<HTMLInputElement>(null);

  // audio record 영역

  const [isRecording, setIsRecording] = useState(false);
  const [receivedText, setReceivedText] = useState("");
  const socket = useRef<WebSocket | null>(null); // ✅ 타입 명시
  const audioContext = useRef(null);
  const scriptProcessor = useRef(null);
  const audioStream = useRef(null);

  // WebSocket 연결 관리
  const connectWebSocket = () => {
    if (socket.current instanceof WebSocket) {
      console.log("기존 WebSocket 닫는 중...");
      socket.current.close(); // ✅ TypeScript가 'WebSocket' 타입으로 인식하도록 변경
      socket.current = null;
    }

    console.log("WebSocket 연결 시도...");
    const newSocket: WebSocket = new WebSocket(
      `wss://${process.env.REACT_APP_VOICE_CONVERT_URL}/ws/convert_audio`,
      // `http://localhost:8000/ws/convert_audio`,
    );

    newSocket.onopen = () => {
      console.log("✅ WebSocket 연결됨.");
    };

    newSocket.onmessage = (event: MessageEvent) => {
      setReceivedText((prevText) => `${prevText} ${event.data}`);
    };

    newSocket.onclose = (event: CloseEvent) => {
      console.log("❌ WebSocket 종료됨", event.code, event.reason);
      // setTimeout(connectWebSocket, 3000); // 3초 후 자동 재연결
    };

    newSocket.onerror = (event: Event) => {
      console.error("❌ WebSocket 오류 발생", event);
      newSocket.close();
    };

    socket.current = newSocket; // ✅ WebSocket 객체를 socket.current에 저장
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket.current instanceof WebSocket) {
        console.log("WebSocket 종료");
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);
  const startRecording = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter(
        (device) => device.kind === "audioinput",
      );
      if (microphones.length === 0) {
        ToastError(TOAST_TEXT.CHATBOT_MIC_ERROR);
        return false;
      }
    } catch (error) {
      console.error("장치를 확인하는 중 오류가 발생했습니다.", error);
      return false;
    }

    if (!cancerSelect?.title) {
      ToastError(TOAST_TEXT.CHATBOT_ONCHANGE_ERROR);
      return null;
    }
    if (isRecording) return; // 이미 녹음 중이면 아무 작업도 하지 않음

    setReceivedText(""); // 기존 텍스트 초기화
    setIsRecording(true);

    // 마이크 권한 요청 및 오디오 설정
    //@ts-expect-error
    audioStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    //@ts-expect-error
    audioContext.current = new AudioContext({ sampleRate: 16000 });

    //@ts-expect-error
    const source = audioContext.current.createMediaStreamSource(
      audioStream.current,
    );

    // ScriptProcessorNode 생성
    //@ts-expect-error
    scriptProcessor.current = audioContext.current.createScriptProcessor(
      4096,
      1,
      1,
    );
    source.connect(scriptProcessor.current);
    //@ts-expect-error
    scriptProcessor.current.connect(audioContext.current.destination);

    //@ts-expect-error
    scriptProcessor.current.onaudioprocess = (audioProcessingEvent) => {
      const inputBuffer = audioProcessingEvent.inputBuffer;
      const inputData = inputBuffer.getChannelData(0); // Float32Array

      // Float32Array를 Int16Array로 변환
      const int16Data = Float32ToInt16(inputData);

      // Int16Array의 바이너리 데이터(ArrayBuffer)로 WebSocket 전송
      if (socket.current && socket.current.readyState === WebSocket.OPEN) {
        socket.current.send(int16Data.buffer);
      }
    };
  };

  const stopRecording = () => {
    if (!isRecording) return; // 녹음 중이 아니면 아무 작업도 하지 않음

    setTimeout(() => {
      if (scriptProcessor.current) {
        //@ts-expect-error
        scriptProcessor.current.disconnect();
        scriptProcessor.current = null;
      }
      if (audioContext.current) {
        //@ts-expect-error
        audioContext.current.close();
        audioContext.current = null;
      }
      if (audioStream.current) {
        //@ts-expect-error
        audioStream.current.getTracks().forEach((track) => track.stop());
        audioStream.current = null;
      }

      setIsRecording(false);
      //@ts-expect-error
    }, "2000");
  };

  // Float32Array를 Int16Array로 변환
  //@ts-expect-error
  const Float32ToInt16 = (float32Array) => {
    const int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i])); // 클리핑
      int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff; // Float32 -> Int16 변환
    }
    return int16Array;
  };

  useEffect(() => {
    if (receivedText) {
      setInputValue((prevInput) => prevInput + " " + receivedText);
      setReceivedText("");

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = inputRef.current.value.length;
          inputRef.current.selectionEnd = inputRef.current.value.length;

          inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        }
      }, 0);
    }
  }, [receivedText]); // receivedText가 변경될 때만 실행

  const chatbotInfo = localStorage.getItem("chatbotInfo");
  //@ts-ignore
  const expiryDate = JSON.parse(chatbotInfo)?.expiry;
  // 로컬스토리지가 만료되면 ?
  let isExpired = expiryDate ? Date.now() > expiryDate : true;

  const [showInfo, setShowInfo] = useState(isExpired ? true : false);

  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state?.auth);
  const {
    userProfile = null,
    userAuthentication = null,
    healthInfo = null,
  } = auth?.userData?.data || {};

  const [isAllowed, setIsAllowed] = useState(auth?.isLoggedIn ? true : false);

  const { setCurrentKeyword, currentKeyword } = useHospitalStore();

  const languageType = [
    {
      title: "한국어",
    },
    {
      title: "English",
    },
  ];

  const openLanguageSetting = () => {
    setCancerSelect({
      title: "",
      api: "",
      type: "",
    });
    setIsSettingActive(!isSettingActive);
  };

  const handleLanguageSetting = (v: T.LanguageType) => {
    setLanguage(v.title);
    setCancerSelect({
      title: "",
      api: "",
      type: "",
    });
    setIsSettingActive(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (cancerSelect?.title) {
      setInputValue(e.target.value);
    } else {
      ToastError(TOAST_TEXT.CHATBOT_ONCHANGE_ERROR);
    }
  };

  const sendMessage = async () => {
    if (inputValue.trim() && cancerSelect?.api) {
      const newMessage: Message = { sender: "user", text: inputValue };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
      setIsLoading(true);

      const info = {
        userName: isAllowed ? userProfile?.nickname : null,
        query: inputValue,
        type: cancerSelect.type,
        healthInfo: isAllowed ? healthInfo : null,
      };

      console.log(info);
      try {
        const response = await requestChatbot(cancerSelect.api, info);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "ai", text: response?.answer },
        ]);
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const recommendHospital = (category: string, index: number) => {
    setShowHospital(true);
    setCurrentKeyword(category);
    setCurrentMsg(index);
  };

  const accessHospitalPage = (category: string) => {
    navigate("/hospital", { state: category });
    setCurrentKeyword(category);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, recommendHospital]);

  useEffect(() => {
    setMessages([]);
  }, [cancerSelect?.title, language]);

  useEffect(() => {
    if (isExpired) {
      localStorage.removeItem("chatbotInfo");
    }
  }, [isExpired]);

  const [loadingMessage, setLoadingMessage] =
    useState<string>("답변을 입력하는 중입니다");

  // info 창을 여는 함수
  const handleShowInfo = () => {
    const now = new Date();
    const item = {
      value: false,
      expiry: now.getTime() + 1000 * 60 * 60 * 24,
    };
    if (showInfo) {
      setShowInfo(false);
      localStorage.setItem("chatbotInfo", JSON.stringify(item));
    } else {
      setShowInfo(true);
      localStorage.removeItem("chatbotInfo");
    }
  };

  return (
    <s.ModalOverlay>
      {isExpired && showInfo && (
        <s.ModalInfo onClick={handleShowInfo} src={chatInfo} alt="챗봇 인포" />
      )}
      <s.ModalContent>
        <s.HeaderContainer>
          <s.Header>{language === "한국어" ? "Aidy 챗봇" : "Aidy"}</s.Header>
          <s.SubTitle>
            {language === "한국어" ? "건강자원포털 하루" : "HARU"}
          </s.SubTitle>

          {auth.isLoggedIn && (
            <s.AuthBtn onClick={() => setIsAllowed(!isAllowed)}>
              {isAllowed ? (
                <s.AuthImg
                  data-tooltip-id="allow"
                  data-tooltip-content="사용자 정보제공 동의"
                  src={Allowd}
                  alt="이용동의함"
                />
              ) : (
                <s.AuthImg
                  data-tooltip-id="allow"
                  data-tooltip-content="사용자 정보제공 동의"
                  src={NotAllowed}
                  alt="이용동의안함"
                />
              )}
            </s.AuthBtn>
          )}
          <ReactTooltip id="allow" />
          <s.GuideBtn>
            <s.GuideImg
              src={Info}
              alt="인포"
              onClick={handleShowInfo}
              data-tooltip-id="guide"
              data-tooltip-content="가이드"
            />
          </s.GuideBtn>
          <ReactTooltip id="guide" />
          <s.LanguageBtn onClick={openLanguageSetting}>
            <img
              src={setting}
              alt="setting"
              data-tooltip-id="setting"
              data-tooltip-content="설정"
            />
          </s.LanguageBtn>
          {isSettingActive && !isLoading && (
            <s.SettingContainer>
              {languageType.map((v: T.LanguageType, i: number) => {
                return (
                  <s.Setting onClick={() => handleLanguageSetting(v)} key={i}>
                    {v.title}
                  </s.Setting>
                );
              })}
            </s.SettingContainer>
          )}
          <ReactTooltip id="setting" />
          <s.CloseButton onClick={onClose}>
            <img src={close} alt="close" />
          </s.CloseButton>
        </s.HeaderContainer>
        <s.ChatbotContent className="wrap-vertical">
          <ChatbotSelect language={language} setLanguage={setLanguage} />
          {messages.map((message, index) => (
            <div key={index}>
              {message.sender === "ai" ? (
                <>
                  <s.Icon>
                    <s.ChatbotImg src={chatbotIcon} alt="chatbot" />
                    <s.ChatbotName>
                      {language === "한국어" ? "Aidy 챗봇" : "Aidy"}
                    </s.ChatbotName>
                  </s.Icon>
                  <s.AiMessage>
                    <s.MessageContainer>
                      <s.MessageText>
                        {message?.text?.replaceAll("<br>", "\n")}
                      </s.MessageText>
                    </s.MessageContainer>
                  </s.AiMessage>
                  <ChatbotRecommendation
                    message={message.text}
                    index={index}
                    currentMsg={currentMsg}
                    setShowHospital={setShowHospital}
                    setCurrentKeyword={setCurrentKeyword}
                    setCurrentMsg={setCurrentMsg}
                    language={language}
                  />
                </>
              ) : (
                <s.UserMessage>
                  <s.MessageContainer>
                    {/* <s.Icon as={} /> */}
                    <s.MessageText>
                      {message?.text?.replaceAll("<br>", "\n")}
                    </s.MessageText>
                  </s.MessageContainer>
                </s.UserMessage>
              )}
            </div>
          ))}
          {isLoading && (
            <s.Loading>
              <s.Icon>
                <s.ChatbotImg src={chatbotIcon} alt="chatbot" />
                <s.ChatbotName>
                  {language === "한국어" ? "Aidy 챗봇" : "Aidy"}
                </s.ChatbotName>
              </s.Icon>
              <s.AiMessage>
                <s.MessageContainer>
                  <s.MessageText>
                    {language === "한국어" ? loadingMessage : "Typing answer"}
                    <s.LoadingDot1 src={loading1} alt="loading" />
                    <s.LoadingDot2 src={loading2} alt="loading" />
                    <s.LoadingDot3 src={loading3} alt="loading" />
                  </s.MessageText>
                </s.MessageContainer>
              </s.AiMessage>
            </s.Loading>
          )}
          <div ref={messagesEndRef} />
          <s.InputContainer>
            <s.ChatInput
              ref={inputRef}
              type="text"
              value={inputValue}
              disabled={isLoading ? true : false}
              onChange={handleInputChange}
              placeholder={
                language === "한국어"
                  ? "메세지를 입력하세요..."
                  : "Type a message..."
              }
              onKeyPress={handleKeyPress}
            />
            <s.MicIcon
              isRecording={isRecording}
              onClick={isRecording ? stopRecording : startRecording}
            >
              <Mic color={isRecording ? "#6dc491" : "gray"} />
            </s.MicIcon>
            <s.SendButton onClick={sendMessage}>
              <img src={send} alt="send" />
            </s.SendButton>
          </s.InputContainer>
        </s.ChatbotContent>
      </s.ModalContent>
    </s.ModalOverlay>
  );
};

export default ChatbotModal;

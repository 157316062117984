export const TOAST_TEXT = {
  MAP_PROVINCE_ERROR: "행정구역을 선택해주세요.",
  MAP_CITY_ERROR: "시군구를 선택해주세요.",
  MAP_HOSPITAL_ERROR: "병원을 찾을 수 없습니다.",

  CHATBOT_SELECT_ERROR: "서비스 준비중입니다.",
  CHATBOT_SELECT_EN_ERROR: "coming soon",
  CHATBOT_ONCHANGE_ERROR: "암종을 먼저 선택해주세요.",

  CHATBOT_MIC_ERROR: "현재 마이크가 연결되어있지 않습니다.",

  // login
  LOGIN_WARN: "로그인이 필요한 작업입니다.",
  LOGOUT_SUCCESS: "로그아웃 되었습니다.",
  LOGIN_SESSION_ERROR: "세션이 만료되었거나 비정상적인 접근입니다.",
  LOGIN_EXPIRED_ERROR: "로그인 정보가 만료되었습니다. 다시 로그인 해주세요.",
  LOGIN_UNDEFINED_ERROR: "알 수 없는 오류입니다. ",

  //mypage
  MYPAGE_EDIT_SUCCESS: "건강정보 수정이 완료되었습니다.",
  PROFILE_EDIT_SUCCESS: "프로필 수정이 완료되었습니다.",
  PROFILE_NICKNAME_ERROR: "닉네임을 한글자 이상 입력해주세요.",
  PROFILE_EDIT_ERROR: "변경된 내용이 없습니다.",

  //prediction
  PREDICTION_RESULT_ERROR: "결과를 불러올 수 없습니다.",

  // inform
  PRINT_SUCCESS: "프린트를 성공적으로 완료하였습니다.",
  URL_COPY_SUCCESS: "클립보드에 링크가 복사되었습니다.",

  // ocr
  ATTACH_IMAGE_ERROR: "최소 1장의 이미지를 첨부해주세요.",
  IMAGE_SIZE_MAX_ERROR: "최대 10MB 이미지를 첨부할 수 있습니다.",
  IMAGE_COUNT_ERROR: "최대 5장의 이미지를 첨부할 수 있습니다.",
  OCR_IMAGE_ERROR: "오류가 발생하였습니다. 처음부터 다시 시도해주세요.",
};

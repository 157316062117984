import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";

interface HospitalSearchContainerProps {
  $searchmenuopen: boolean;
}

export const TotalContainer = styled.div`
  @media screen and (max-width: 1279px) {
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HospitalSearchForm = styled.form`
  display: flex;
  align-items: center;
  background-color: white;
  border: 3px solid #6dc491;
  border-radius: 30px;
  padding: 5px;
  z-index: 4;
  @media screen and (max-width: 1279px) {
    margin-top: -20px;
  }
`;

export const SearchInput = styled.input`
  width: 90%;
  height: 35px;
  color: #9d9d9d;
  margin-left: 10px;
  outline: none;
  border: none;
  border-radius: 30px;
  font-size: 15px;
`;

export const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const closeIcon = styled.img`
  width: 17px;
  cursor: pointer;
`;

export const searchIcon = styled.img`
  width: 20px;
  margin-left: 2px;
`;

export const SearchListWrapper = styled.div`
  z-index: 3;

  margin-top: 70px;
  align-items: center;
  width: 90%;
  border-radius: 10px;
  height: 90%;
  padding: 0 10px;
  overflow-y: auto;

  @media screen and (max-width: 1279px) {
    position: absolute;

    top: 130px;
    left: 0;
    padding: 0;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    background-color: #f0f8f7;
    border: none;
    box-shadow: none;
    height: 90%;
    border-radius: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
`;

import React, { useEffect } from "react";
import { getPharmacyDistrictPage } from "api/LocationApi";
import { useInfiniteQuery } from "react-query";
import useHospitalStore from "../../../../../zustand/store/hospital";
import { ToastError } from "../../../../../libs/toastifyAlert";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import * as t from "../../../components/Sidebar/welfare/style.hospitalwelfare";
import pharmacy from "assets/hospital/pharmacyImg.png";
import HospitalBox from "../../HospitalBox";
import pharmacyImg from "assets/hospital/pharmacyEmpty.png";

const PharmacyList = () => {
  const {
    setHospital,
    userLocation,
    currentProvince,
    currentCity,
    setHospitalType,
  } = useHospitalStore();

  const {
    isLoading: isLoadingHospitalsByCategory,
    isError: isErrorHospitalsByCategory,
    data: PharmacyPagableData,
    fetchNextPage,
    hasNextPage,
    isSuccess,
  } = useInfiniteQuery(
    ["pharmacyDistrictPage", currentCity],
    ({ pageParam = 0 }) =>
      getPharmacyDistrictPage({
        lat: userLocation.lat,
        lng: userLocation.lng,
        doNm: currentProvince,
        sigunguNm: currentCity,
        size: 50,
        page: pageParam,
        type: "ALL",
      }),
    {
      enabled: !!currentCity,
      getNextPageParam: (lastPage) => {
        if (
          lastPage &&
          lastPage.data &&
          lastPage.data.number != null &&
          lastPage.data.totalPages != null
        ) {
          return lastPage.data.number < lastPage.data.totalPages - 1
            ? lastPage.data.number + 1
            : undefined;
        } else {
          return undefined;
        }
      },
    },
  );

  useEffect(() => {
    if (isSuccess && PharmacyPagableData?.pages[0].data) {
      setHospital(PharmacyPagableData?.pages[0].data.content as any);
      setHospitalType("pharmacy");
    } else if (PharmacyPagableData?.pages[0].error) {
      ToastError(PharmacyPagableData?.pages[0].error.message);
    }
  }, [PharmacyPagableData, isSuccess, setHospital]);
  return (
    <>
      {PharmacyPagableData?.pages[0].data ? (
        <Container>
          <Wrapper className="wrap-vertical">
            <InfiniteScroll
              pageStart={0}
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage()}
              useWindow={false}
            >
              {PharmacyPagableData?.pages.map((page, index) => (
                <div key={index}>
                  {page.data.content.map((v: any, idx: number) => (
                    <HospitalBox boxProps={{ ...v, type: "pharmacy" }} />
                  ))}
                </div>
              ))}
            </InfiniteScroll>
          </Wrapper>
        </Container>
      ) : (
        <PhamacyImg src={pharmacyImg} alt="약국 대체이미지" />
      )}
      <Img src={pharmacy} alt="pharmacy" />
    </>
  );
};
export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  width: 90%;
  height: 90%;
  margin: auto;
  @media screen and (max-width: 1279px) {
    height: 85%;
    margin-bottom: 70px;
  }
`;

export const Img = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 380px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  height: 75vh;
  background-color: white;
  overflow-y: auto;
  @media screen and (max-width: 1279px) {
    width: 90vw;
    height: 85%;
  }
`;

export const PhamacyImg = styled.img`
  max-width: 250px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 1279px) {
    margin-top: 50px;
  }
`;

export const Item = styled.div`
  border-top: 1px solid #4b4b4b;
  padding: 15px 0px;
  cursor: pointer;

  & > div {
    margin-bottom: 5px;
    color: #333;
    &:last-child {
      margin-bottom: 0;
    }
  }

  & .pharmacy-name {
    font-weight: bold;
  }

  & .distance {
    color: #666;
    font-size: 0.9em;
  }
`;

export default PharmacyList;

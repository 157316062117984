import styled, { keyframes, css } from "styled-components";

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  right: 70px;
  bottom: 70px;
  z-index: 5;

  @media screen and (max-width: 1279px) {
    right: 60px;
    bottom: 60px;
  }
  @media screen and (max-width: 640px) {
    background-color: white;
    z-index: 3;
    right: 0;
    bottom: 0;
    top: 70px;
    width: 100%;
    height: calc(100vh - 70px);
  }
`;

const ModalInfo = styled.img`
  position: absolute;
  z-index: 10;
  max-width: 540px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 640px) {
    max-width: none;
    width: 100%;
  }
`;

const ModalContent = styled.div`
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  background-color: white;
  @media screen and (max-width: 640px) {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    max-height: none;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid #d8d8d8;
  }
`;

const CancerTypeContainer = styled.div`
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const CancerTypeTitle = styled.div<{ isclicked: boolean }>`
  text-align: center;
  color: #3c6e51;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  color: ${(props) => (props.isclicked ? "#ffffff" : "#3c6e51")};
  font-weight: ${(props) => (props.isclicked ? "normal" : "bold")};
  @media screen and (max-width: 640px) {
    font-size: 11px;
    padding: 5px 8px;
  }
`;

const CancerTypeImg = styled.img<{ isclicked: boolean }>`
  height: 30px;
  margin-bottom: 5px;
  filter: ${(props) => (props.isclicked ? "brightness(0) invert(1)" : "")};

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

const CancerTypeWrapper = styled.div<{ isclicked: boolean }>`
  background-color: #e8f3ed;
  border: 1px solid #6dc491;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-right: 10px;
  justify-content: center;
  background-color: ${(props) => (props.isclicked ? "#6dc491" : "#e8f3ed")};

  &:hover {
    background-color: #6dc491;
    color: white;
    cursor: pointer;
    ${CancerTypeTitle} {
      color: white;
      font-weight: normal;
    }
    ${CancerTypeImg} {
      filter: brightness(0) invert(1); /* 아이콘에도 호버 효과 적용 */
    }
  }

  @media screen and (max-width: 640px) {
    width: auto;
    height: auto;
  }
`;

const HeaderContainer = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Header = styled.h2`
  font-size: 15px;
  color: #505050;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const AuthBtn = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 12px;
  right: 69px;
  cursor: pointer;
`;

const AuthImg = styled.img`
  width: 25px;
`;

const GuideBtn = styled.button`
  background-color: transparent;
  position: absolute;
  left: 10px;
  top: 12px;
  border: none;
`;

const GuideImg = styled.img`
  width: 25px;
  cursor: pointer;
`;

const LanguageBtn = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 13px;
  right: 37px;
  cursor: pointer;
`;

const SettingContainer = styled.div`
  background-color: white;
  color: #505050;
  position: absolute;
  top: 45px;
  right: 40px;
  width: 80px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
`;

const Setting = styled.div`
  margin: 5px 0px;
  cursor: pointer;
  padding: 5px 0px;
  &:hover {
    background-color: #e8f3ed;
    border-radius: 10px;
  }
`;

const SubTitle = styled.p`
  font-size: 10px;
  margin-top: 3px;
  color: #505050;
  @media screen and (max-width: 640px) {
    font-size: 8px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ChatbotName = styled.p`
  font-size: 11px;
  margin-top: 3px;
  @media screen and (max-width: 640px) {
    font-size: 10px;
  }
`;

const ChatbotContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 60vh;
  overflow-y: auto;
  margin-bottom: 60px;
  margin-top: 40px;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    height: 70vh;
  }
`;

const Message = styled.div`
  max-width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 640px) {
    padding: 7px;
    margin-bottom: 7px;
  }
`;

const AiMessage = styled(Message)`
  background-color: #f3f3f3;
  margin-top: 10px;
  float: left;
  color: #505050;
  border-radius: 0px 10px 10px 10px;
  align-self: flex-start;
  @media screen and (max-width: 640px) {
    margin-bottom: 7px;
    border-radius: 0px 7px 7px 7px;
  }
`;
const RecommendButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: 2px solid #6dc491;
  background-color: transparent;
  color: #6dc491;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #6dc491;
    color: white;
  }
`;

const RecommendWelfareButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: 2px solid #60bef5;
  background-color: transparent;
  color: #60bef5;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #60bef5;
    color: white;
  }
`;

const UserMessage = styled(Message)`
  background-color: #6dc491;
  float: right;
  color: white;
  align-self: flex-end;
  border-radius: 10px 0px 10px 10px;
  @media screen and (max-width: 640px) {
    border-radius: 7px 0px 7px 7px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Icon = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
`;

const MessageText = styled.span`
  font-size: 15px;
  flex: 1;
  line-height: 1.5;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const InputContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  background-color: white;
  gap: 10px;
`;

const ChatbotImg = styled.img`
  /* @media screen and (max-width: 640px) {
    width: 40px;
  } */
`;

const ChatInput = styled.input`
  flex: 1;
  position: relative;
  padding: 10px 40px 10px 10px;
  border-radius: 5px;
  background-color: #eee;
  outline: none;
  border: none;

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
`;

const MicIcon = styled.div<{ isRecording: boolean }>`
  position: absolute;
  height: 40px;
  right: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  // 🔥 isRecording이 true일 때 깜빡이는 효과 적용
  ${({ isRecording }) =>
    isRecording &&
    css`
      animation: ${blink} 1s infinite ease-in-out;
    `}
`;

const SendButton = styled.button`
  padding: 10px 10px 7px 10px;
  background-color: #6dc491;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
`;

const Loading = styled.div`
  text-align: center;
  color: #333;
  font-size: 18px;

  & .loading_dot {
    display: inline-block;
    animation: loadingDot 1s infinite;
  }

  @keyframes loadingDot {
    50% {
      opacity: 0;
    }
  }
`;

const LoadingDot1 = styled.img`
  margin-left: 6px;
  animation: loadingDot 1.5s ease-in-out infinite;
`;

const LoadingDot2 = styled.img`
  margin-left: 6px;
  animation: loadingDot 1.5s ease-in-out infinite;
  animation-delay: 0.5s;
`;

const LoadingDot3 = styled.img`
  margin-left: 6px;
  animation: loadingDot 1.5s ease-in-out infinite;
  animation-delay: 1s;
`;

const Container = styled.div``;

const ChatSideText = styled.div`
  background-color: white;
  font-size: 20px;
  padding: 3px 25px 3px 20px;
  right: 90px;
  font-size: 16px;
  bottom: 15px;
  border: 1px solid #747474;
  border-radius: 30px 0 0 30px;
  transform: translate(0, -50%);
  z-index: 1;
  line-height: 40px;
  position: fixed;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

const ChatbotIconContainer = styled.div`
  z-index: 2;
  bottom: 0;
  position: fixed;
  right: 0;
  cursor: pointer;
`;

const ClickText = styled.div`
  color: white;
  position: absolute;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const ChatbotIcon = styled.img`
  z-index: 2;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 90px;
  border-radius: 600px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1279px) {
    width: 60px;
  }
`;

export {
  ModalOverlay,
  ModalInfo,
  ModalContent,
  HeaderContainer,
  ChatbotName,
  Header,
  CloseButton,
  ChatbotContent,
  AiMessage,
  UserMessage,
  MessageContainer,
  Icon,
  MessageText,
  InputContainer,
  ChatInput,
  ChatbotIcon,
  SendButton,
  AuthBtn,
  GuideBtn,
  AuthImg,
  Loading,
  SubTitle,
  LoadingDot1,
  LoadingDot2,
  LoadingDot3,
  CancerTypeContainer,
  CancerTypeWrapper,
  CancerTypeTitle,
  CancerTypeImg,
  LanguageBtn,
  GuideImg,
  SettingContainer,
  Setting,
  ChatbotImg,
  ClickText,
  ChatbotIconContainer,
  RecommendButton,
  RecommendWelfareButton,
  ChatSideText,
  Container,
  MicIcon,
};

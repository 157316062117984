import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

const GlobalStyle = createGlobalStyle`
 ${reset}

 /* Quill Editor에 대한 추가 스타일 */
/* Quill Editor에 대한 추가 스타일 */
.ql-editor h1 {
  font-size: 32px;
  font-weight: bold;
}

.ql-editor h2 {
  font-size: 24px;
}

.ql-editor h3 {
  font-size: 20px;
}

.ql-editor h4 {
  font-size: 18px;
}

.ql-editor h5 {
  font-size: 16px;
}

.ql-editor b {
  font-weight: bold;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor i {
  font-style: italic;
}

.ql-editor em {
  font-style: italic;
}

.ql-editor u {
  text-decoration: underline;
}

.ql-editor s {
  text-decoration: line-through;
}


 @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'yg-jalnan';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JalnanGothic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'iceJaram-Rg';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-2@1.0/iceJaram-Rg.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KCC-Hanbit';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/2403-2@1.0/KCC-Hanbit.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NEXON Lv2 Gothic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv2 Gothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
     font-family: 'S-CoreDream-3Light';
     src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}

@font-face {
    font-family: 'GangwonEdu_OTFBoldA';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KBO-Dia-Gothic_bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/KBO-Dia-Gothic_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'LINESeedKR-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SEBANG_Gothic_Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2104@1.0/SEBANG_Gothic_Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GangwonEduPowerExtraBoldA';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PFStardust';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/PFStardust.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GongGothicMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


* {
    text-decoration: none;
    list-style: none;
}

body {
    font-family: 'Pretendard-Regular';
}

.radius_border{
  border:1px solid #919191;
  border-radius:5px;
}    

.custom_typecontrol {
  position:absolute;
  top:10px;
  right:10px;
  overflow:hidden;
  width:130px;
  height:30px;
  margin:0;
  padding:0;
  z-index:5;
  font-size:12px;
  font-family:'Malgun Gothic', '맑은 고딕', sans-serif;
}

.custom_typecontrol span {
  display:block;
  width:65px;
  height:30px;
  float:left;
  text-align:center;
  line-height:30px;
  cursor:pointer;
}

.custom_typecontrol .btn {
  background:#fff;
  background:linear-gradient(#fff,  #e6e6e6);
}     

.custom_typecontrol .btn:hover {
  background:#f5f5f5;
  background:linear-gradient(#f5f5f5,#e3e3e3);
}

.custom_typecontrol .btn:active {
  background:#e6e6e6;
  background:linear-gradient(#e6e6e6, #fff);
}    

.custom_typecontrol .selected_btn {
  color:#fff;
  background:#425470;
  background:linear-gradient(#425470, #5b6d8a);
}

.custom_typecontrol .selected_btn:hover {
  color:#fff;
}   
.custom_zoomcontrol {
  position:absolute;
  top:50px;
  right:10px;
  width:36px;
  height:80px;
  overflow:hidden;
  z-index:5;
  background-color:#f5f5f5;
} 

.custom_zoomcontrol span {
  display:block;
  width:36px;
  height:40px;
  text-align:center;
  cursor:pointer;
}     

.custom_zoomcontrol span img {
  width:15px;
  height:15px;
  padding:12px 0;
  border:none;
}             

.custom_zoomcontrol span:first-child{
  border-bottom:1px solid #bfbfbf;
}            

.wrap-vertical::-webkit-scrollbar {
    display: none; 
}

.not-allow-drag {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

 .textEffect {
  font-family: 'NEXON Lv2 Gothic';
} 


@keyframes chatbotText{
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
}


@keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .blinking-text {
    animation: blink 2s infinite;
  }

  .blinking-text2 {
    animation-delay: 1s; 
    animation: blink 1.5s infinite;

  }

  .pulse-effect {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}


// radio button
// 기본스타일을 없애고, 버튼모양을 구현.
input[type='radio'] {
  -webkit-appearance: none; // 웹킷 브라우저에서 기본 스타일 제거
  -moz-appearance: none; // 모질라 브라우저에서 기본 스타일 제거 
  appearance: none; // 기본 브라우저에서 기본 스타일 제거
  width: 18px;
  height: 18px;
  border: 2px solid #ccc; // 체크되지 않았을 때의 테두리 색상
  border-radius: 50%;
  outline: none; // focus 시에 나타나는 기본 스타일 제거
  cursor: pointer;
}

// 체크될 시에, 변화되는 스타일 설정
input[type='radio']:checked {
  background-color:  #4cbeb3; // 체크 시 내부 원으로 표시될 색상
  border: 3px solid white; // 테두리가 아닌, 테두리와 원 사이의 색상
  box-shadow: 0 0 0 1.6px  #4cbeb3; // 얘가 테두리가 됨
  // 그림자로 테두리를 직접 만들어야 함 (퍼지는 정도를 0으로 주면 테두리처럼 보입니다.)
  // 그림자가 없으면 그냥 설정한 색상이 꽉 찬 원으로만 나옵니다.
}

input[type='radio'] {
  transition: border 0.2s ease-in-out;
}

// toastify
:root {
  --toastify-font-family: 'Pretendard-Regular';
}


// checkbox

// 기본스타일을 없애고, 버튼모양을 구현.
input[type='checkbox'] {
  -webkit-appearance: none; // 웹킷 브라우저에서 기본 스타일 제거
  -moz-appearance: none; // 모질라 브라우저에서 기본 스타일 제거 
  appearance: none; // 기본 브라우저에서 기본 스타일 제거
  width: 18px;
  height: 18px;
  border: 2px solid #ccc; // 체크되지 않았을 때의 테두리 색상
  border-radius: 50%;
  outline: none; // focus 시에 나타나는 기본 스타일 제거
  cursor: pointer;
}

// 체크될 시에, 변화되는 스타일 설정
input[type='checkbox']:checked {
  background-color:  #4cbeb3; // 체크 시 내부 원으로 표시될 색상
  border: 3px solid white; // 테두리가 아닌, 테두리와 원 사이의 색상
  box-shadow: 0 0 0 1.6px  #4cbeb3; // 얘가 테두리가 됨
  // 그림자로 테두리를 직접 만들어야 함 (퍼지는 정도를 0으로 주면 테두리처럼 보입니다.)
  // 그림자가 없으면 그냥 설정한 색상이 꽉 찬 원으로만 나옵니다.
}

input[type='checkbox'] {
  transition: border 0.2s ease-in-out;
}


// welfare-pagination 

.welfare-page {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  position: absolute;
  bottom: 30px;
  @media screen and (max-width: 1279px) {
    
    position: static;
    margin-top: 0px;
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul.welfare-page li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
}

ul.welfare-page li:first-child{
  border-radius: 5px 0 0 5px;
  background-color: white;
}

ul.welfare-page li:last-child{
  border-radius: 0 5px 5px 0;
}

ul.welfare-page li a {
  text-decoration: none;
  color: #46835f;

  font-size: 1rem;
}

ul.welfare-page li.active a {
  color: white;
}

ul.welfare-page li.active {
  background-color: #6CC18F;

}

ul.pagination li a:hover,
ul.pagination li a.active {

}

.page-selection {
  width: 48px;
  height: 30px;
  color: #505050;
}

// admin-pagination
.admin-page {
  display: flex;
  position: absolute;
  justify-content: center;
  margin-top: 15px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
}

ul {
  list-style: none;
  padding: 0;
}

ul.admin-page li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
  border: none;
}

ul.admin-page li:first-child{
  border-radius: 5px 0 0 5px;
  background-color: white;
}

ul.admin-page li:last-child{
  border-radius: 0 5px 5px 0;
}

ul.admin-page li a {
  text-decoration: none;
  color: #505050;

  font-size: 1rem;
}

ul.admin-page li.active a {
  color: white;
}

ul.admin-page li.active {
  background-color: #505050;
  border-radius: 100px;
}

// announcement pagiantion

.announcement-page {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  @media screen and (max-width: 1279px) {
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul.announcement-page li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
  border: none;
}

ul.announcement-page li:first-child{
  border-radius: 5px 0 0 5px;
  background-color: white;
}

ul.announcement-page li:last-child{
  border-radius: 0 5px 5px 0;
}

ul.announcement-page li a {
  text-decoration: none;
  color: #505050;

  font-size: 1rem;
}

ul.announcement-page li.active a {
  color: white;
}

ul.announcement-page li.active {
  background-color: #505050;
  border-radius: 10px;
}

.mainTitle {
  font-family: 'yg-jalnan';
  color: #ffffff;
  text-shadow: 3px 3px 3px gray;
}

.moveImg {
  animation: up-down 1.4s infinite ease-in-out alternate;
}

@keyframes up-down{
  from{
    transform: translate(-50%, -50%); 
  }
  to{
    transform: translate(-50%,-54%);
  }
}


.trainImg {
  animation: go-forward 7s infinite ease-in-out alternate;
}


@keyframes go-forward{
  from{
    transform: translate(-50%, -50%); 
  }
  to{
    transform: translate(-110%,-12%);
  }
}

@media (max-width: 1279px) {
  .shadowImg {
    animation: tutsFade2 1.4s infinite ease-in-out alternate;
  }
}

@media (min-width: 1280px) {
  .shadowImg {
    animation: tutsFade 1.4s infinite ease-in-out alternate;
  }
}


@keyframes tutsFade {
  from {
    opacity: 0.9;
    width: 633px;
  }
  to {

    opacity: 0.6;
    width: 640px; 
  }
}

@keyframes tutsFade2 {
  from {
    opacity: 0.9;
    width: 270px;
  }
  to {

    opacity: 0.6;
    width: 280px; 
  }
}

// 스크롤바 커스텀

/* 임의의 영역 생성 */
.scrollBar { 
}

/* 아래의 모든 코드는 영역::코드로 사용 */
.scrollBar::-webkit-scrollbar {
    width: 12px;  /* 스크롤바의 너비 */
    height: 12px;
}

.scrollBar::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: #969696; /* 스크롤바의 색상 */
    border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
    background: rgba(151, 151, 151, 0.1);  /*스크롤바 뒷 배경 색상*/
}


// 안내시스템

// font-family: 'NEXON Lv2 Gothic';
// font-family:  'KCC-Hanbit';

.total {
  font-family: 'SUIT-Regular';
}

@font-face {
    font-family: 'JalnanGothic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.informTitle {
  font-family: 'SUIT-Regular';
  font-family: 'yg-jalnan';

}



.field {
  font-family: 'SUIT-Regular';

  
}

.systemName {
  font-family: 'SUIT-Regular';
}

.systemDescription {
  font-family: 'NEXON Lv2 Gothic';
}

// main 뉴스

.news {
  font-family: 'SUIT-Regular';
}

.newsTitle {
  font-family: 'GangwonEduPowerExtraBoldA';
}

.bmiTitle {
  font-family: 'yg-jalnan';
}

.admin {
  font-family: 'SUIT-Regular';
}

.deco {
  font-family: 'PFStardust';
}


.ocrTitle {
  font-family: 'GongGothicMedium';
}

.gmarket {
  font-family: 'GmarketSansMedium';
}


// swiper aidy

.aidySwiper .swiper-slide {
  transition: filter 0.5s ease; /* 부드러운 전환 효과 추가 */
}

.aidySwiper  .swiper-slide:not(.swiper-slide-active) {
  filter: opacity(40%);
}

`;

export default GlobalStyle;
